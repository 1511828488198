<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div style="">
        <div class="text-center">
            <v-dialog
                    v-model="dialog"
                    persistent
                    width="50"
            >
                <img src='../../assets/loader_spinner.gif' style="width: 50px; ">

            </v-dialog>
        </div>
        <v-row>
            <v-col>
                <v-container class="my-5 adminContainer">
                    <v-row>
                        <v-col cols="0" md="2">
                            <admin-room-menu></admin-room-menu>
                        </v-col>
                        <v-col cols="12" md="8">
                            <v-row>
                                <v-col cols="12" class="sub-title-3 mt-2 pb-0">
                                    추가요금
                                    <v-btn
                                            color="error"
                                            class="mx-2 white--text center-block float-right mb-0"
                                            x-small
                                            @click="btnClick(1)"
                                    >
                                        추가
                                    </v-btn>
                                </v-col>
                                <v-col cols="12" class="overflow-x-auto">
                                    <table class="table-top-s table-top-s-b" style="min-width: 700px;">
                                        <colgroup>
                                            <!--<col style="width:16%"/>-->
                                            <col style="width:15%"/>
                                            <col style="width:35%"/>
                                            <col style="width:15%"/>
                                            <col style="width:15%"/>
                                            <col style="width:15%"/>
                                            <col style="width:10%"/>
                                            <col style="width:10%"/>
                                        </colgroup>
                                        <tr>
                                            <!--<th>번호</th>-->
                                            <th>정렬순서</th>
                                            <th>이름</th>
                                            <th>가격</th>
                                            <th>단위</th>
                                            <th>적용</th>
                                            <th>저장</th>
                                            <th>삭제</th>
                                        </tr>
                                        <tr v-for="(item, i) in campItemList"
                                            :key=i
                                        >
                                            <!--<td>
                                                {{item.peakId}}
                                            </td>-->
                                            <td>
                                                <!--<input v-model="item.campId" placeholder="자동생성" readonly>-->
                                                {{item.campOrder}}
                                            </td>
                                            <td >
                                                {{item.campName}}
                                            </td>
                                            <td>
                                                {{item.campPrice | comma}}원
                                            </td>
                                            <td>
                                                {{item.campUnit}}
                                            </td>
                                            <td>
                                                <template v-if="item.useYn === 'Y'">
                                                    <span style="color:blue;">적용</span>
                                                </template>
                                                <template v-else-if="item.useYn === 'N'">
                                                    <span style="color:black;">미적용</span>
                                                </template>
                                                <!--{{item.useYn}}-->
                                            </td>
                                            <td>

                                                <v-btn
                                                        color="primary"
                                                        class="mx-4 white--text center-block float-right"
                                                        x-small
                                                        @click="update(item, 2)"
                                                >
                                                    수정
                                                </v-btn>
                                            </td>
                                            <td>
                                                <v-btn
                                                        color="error"
                                                        class="mx-4 white--text center-block float-right"
                                                        x-small
                                                        @click="update(item, 1)"
                                                >
                                                    삭제
                                                </v-btn>
                                            </td>
                                        </tr>
                                    </table>
                                    <v-col>
                                        <!--<v-btn
                                                color="error"
                                                class="mx-2 white&#45;&#45;text center-block float-right"
                                                x-small
                                                @click="btnClick(1)"
                                        >
                                            추가
                                        </v-btn>-->
                                    </v-col>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>

                </v-container>
            </v-col>
        </v-row>
        <!--<v-row>
            <v-col>
                <v-container class="my-5 adminContainer">
                    <v-row>
                        <v-col cols="0" md="2">
                            <admin-room-menu></admin-room-menu>
                        </v-col>
                        <v-col cols="12" md="8">
                            <v-row>
                                <v-col cols="12" class="sub-title-3 mt-2 pb-0">
                                    추가요금
                                    <v-btn
                                            color="error"
                                            class="mx-2 white&#45;&#45;text center-block float-right mb-0"
                                            x-small
                                            @click="btnClick(1)"
                                    >
                                        추가
                                    </v-btn>
                                </v-col>
                                <v-col cols="12" class="overflow-x-auto">
                                    <table class="table-top-s table-top-s-b" style="min-width: 700px;">
                                        <colgroup>
                                            &lt;!&ndash;<col style="width:16%"/>&ndash;&gt;
                                            <col style="width:10%"/>
                                            <col style="width:25%"/>
                                            <col style="width:15%"/>
                                            <col style="width:15%"/>
                                            <col style="width:15%"/>
                                            <col style="width:10%"/>
                                            <col style="width:10%"/>
                                        </colgroup>
                                        <tr>
                                            &lt;!&ndash;<th>번호</th>&ndash;&gt;
                                            <th>번호</th>
                                            <th>이름</th>
                                            <th>가격</th>
                                            <th>단위</th>
                                            <th>적용</th>
                                            <th>저장</th>
                                            <th>삭제</th>
                                        </tr>
                                        <tr v-for="(item, i) in campItemList"
                                            :key=i
                                        >
                                            &lt;!&ndash;<td>
                                                {{item.peakId}}
                                            </td>&ndash;&gt;
                                            <td>
                                                &lt;!&ndash;<input v-model="item.campId" placeholder="자동생성" readonly>&ndash;&gt;
                                                {{i}}
                                            </td>
                                            <td>
                                                <input v-model="item.campName" placeholder="이름">
                                            </td>
                                            <td>
                                                <input v-model="item.campPrice" placeholder="가격" style="text-align: right;">
                                            </td>
                                            <td>
                                                <input v-model="item.campUnit" placeholder="단위">
                                            </td>
                                            <td>
                                                <select v-model="item.useYn" style="width: 100%">
                                                    <option value="Y">적용</option>
                                                    <option value="N">미적용</option>
                                                </select>
                                            </td>
                                            <td>

                                                <v-btn
                                                        color="primary"
                                                        class="mx-4 white&#45;&#45;text center-block float-right"
                                                        x-small
                                                        @click="update(item, 0)"
                                                >
                                                    저장
                                                </v-btn>
                                            </td>
                                            <td>
                                                <v-btn
                                                        color="error"
                                                        class="mx-4 white&#45;&#45;text center-block float-right"
                                                        x-small
                                                        @click="update(item, 1)"
                                                >
                                                    삭제
                                                </v-btn>
                                            </td>
                                        </tr>
                                    </table>
                                    <v-col>
                                        &lt;!&ndash;<v-btn
                                                color="error"
                                                class="mx-2 white&#45;&#45;text center-block float-right"
                                                x-small
                                                @click="btnClick(1)"
                                        >
                                            추가
                                        </v-btn>&ndash;&gt;
                                    </v-col>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>

                </v-container>
            </v-col>
        </v-row>-->

        <!--수정팝업-->
        <v-dialog
                v-model="dialog_edit"
                persistent
                width="500"
        >
            <v-col style="background-color: white">
                <v-col cols="12" class="sub-title-3 mt-2 pb-0">
                    환불 확인
                </v-col>
            </v-col>
            <v-col style="background-color: white">
                <table class="table-left-s table-left-s-b">
                    <colgroup>
                        <col style="width:30%"/>
                        <col style="width:70%"/>
                    </colgroup>
                    <tr>
                        <th class="th">순서</th>
                        <td class="td">
                            <input v-model="campItem_edit.campOrder" placeholder="순서" style="width: 100%;">
                        </td>
                    </tr>
                    <tr>
                        <th class="th">이름</th>
                        <td class="td">
                            <input v-model="campItem_edit.campName" placeholder="이름" style="width: 100%;">
                        </td>
                    </tr>
                    <tr>
                        <th class="th">가격</th>
                        <td class="td">
                            <input v-model="campItem_edit.campPrice" placeholder="가격" style="width: 100%;"
                                   @keyup="inputNumberFormat(campItem_edit.campPrice)">
                        </td>
                    </tr>
                    <tr>
                        <th class="th">단위</th>
                        <td class="td">
                            <input v-model="campItem_edit.campUnit" placeholder="단위" style="width: 100%;">
                        </td>
                    </tr>
                    <tr>
                        <th class="th">적용</th>
                        <td class="td">
                            <select v-model="campItem_edit.useYn" style="width: 100%">
                                <option value="Y">적용</option>
                                <option value="N">미적용</option>
                            </select>
                        </td>
                    </tr>

                </table>
                <span style="color:black; font-weight: bold; font-size: 1rem;">
                    ※ 예금주는 입금자명과 같아야 합니다.
                </span>
            </v-col>
            <v-col style="background-color: white">
                <v-col cols="12" style="text-align: center; padding-top:0">
                    <v-btn
                            color="error"
                            class="mx-4 white--text center-block"
                            small
                            @click="update('', 3)"
                    >
                        저장
                    </v-btn>
                    <v-btn
                            color="primary"
                            class="mx-4 white--text center-block"
                            small
                            @click="dialog_edit = false"
                    >
                        취소
                    </v-btn>
                </v-col>
            </v-col>
        </v-dialog>

    </div>
</template>

<script>

    import AdminRoomMenu from '../../components/AdminRoomMenu'

    export default {
        name: 'AdminCampItemList',
        components: {
            AdminRoomMenu
        },
        data: () => ({
            page: 1,
            pageCount: 0,
            itemsPerPage: 30,

            campItemList: [],
            tmpId: 0,
            dialog: false,
            dialog_edit: false,
            campItem_edit: {}


        }),
        created: function () {
            // 맨위로
            if ( this.$isMobile()) {
                window.scrollTo({top: 400, left: 0, behavior: 'smooth'});
            } else {
                window.scrollTo(0, 0);
            }

            //this.$isAdminCheck(this.$store.state.admin.isLogin);
            //this.$isAuthCheck(this.$getCookie("AUTH"));
            this.getList({});
        },
        methods: {
            getList(val) {
                return this.$store.dispatch("admin/getCampItemList", val)
                    .then((resp) => {
                        setTimeout(() => {
                            this.campItemList = resp.message;

                        }, 300)
                    })
                    .catch((err) => {
                    })
            },
            btnClick(val) {
                /*switch (val) {
                    case 1 : {   //

                        let formdata = {
                            //campId: "",
                            campName: "",
                            campPrice: "",
                            lstModPrs: this.$store.state.admin.loginInfo.usrId,
                            tmpId: this.tmpId,
                        };

                        this.campItemList.push(formdata);

                        this.tmpId += this.tmpId + 1;

                        break;
                    }
                }*/

                this.campItem_edit = {
                    useYn: "N"
                };
                /*if (this.campItem_edit.campPrice !== undefined) {
                    this.campItem_edit.campPrice = this.comma(this.campItem_edit.campPrice);
                }*/
                this.dialog_edit = true;

            },
            update(val, isUpdate) {
                switch (isUpdate) {
                    case 0 : {
                        if (!confirm("추가요금을 저장 하시겠습니까?")) {
                            // 취소(아니오) 버튼 클릭 시 이벤트
                        } else {
                            // 확인(예) 버튼 클릭 시 이벤트
                            if (val.campName.length === 0 || val.campPrice.length === 0 || val.campUnit === 0) {
                                alert('항목을 입력해 주세요');

                                return;
                            }

                            this.dialog = true;

                            return this.$store.dispatch("admin/updateCampItem", val)
                                .then((resp) => {
                                    setTimeout(() => {
                                        this.getList({});
                                        this.dialog = false;
                                        alert("저장했습니다.");

                                    }, 300)
                                })
                                .catch((err) => {
                                });
                        }

                        break;
                    }
                    case 1 : {
                        if (!confirm("추가요금을 삭제 하시겠습니까?")) {
                            // 취소(아니오) 버튼 클릭 시 이벤트
                        } else {
                            // 확인(예) 버튼 클릭 시 이벤트
                            if (val.tmpId != undefined) {

                                for (let i = 0; this.campItemList.length > i; i++) {

                                    if (this.campItemList[i].tmpId === val.tmpId) {
                                        this.campItemList.splice(i, 1);
                                    }
                                }
                            } else {
                                this.dialog = true;
                                return this.$store.dispatch("admin/deleteCampItem", val)
                                    .then((resp) => {
                                        setTimeout(() => {
                                            this.getList({});
                                            /*for(let i = 0; this.peakList.length > i ; i++ ){

                                                if(this.peakList[i].peakId === val.peakId){
                                                    this.peakList.splice(i, 1);
                                                }
                                            }*/
                                            this.dialog = false;
                                            alert("삭제 되었습니다.")

                                        }, 300)
                                    })
                                    .catch((err) => {
                                    })
                            }
                        }

                        break;
                    }
                    case 2 :{
                        this.campItem_edit = JSON.parse(JSON.stringify(val));
                        if (this.campItem_edit.campPrice !== undefined) {
                            this.campItem_edit.campPrice = this.comma(this.campItem_edit.campPrice);
                        }
                        this.dialog_edit = true;
                       break;
                    }

                    case 3 :{
                        if (!confirm("추가요금을 저장 하시겠습니까?")) {
                            // 취소(아니오) 버튼 클릭 시 이벤트
                        } else {
                            // 확인(예) 버튼 클릭 시 이벤트
                            if (this.campItem_edit.campName.length === 0 || this.campItem_edit.campPrice.length === 0 || this.campItem_edit.campUnit === 0) {
                                alert('항목을 입력해 주세요');

                                return;
                            }

                            this.dialog = true;
                            this.campItem_edit.campPrice = this.uncomma(this.campItem_edit.campPrice);

                            return this.$store.dispatch("admin/updateCampItem", this.campItem_edit)
                                .then((resp) => {
                                    setTimeout(() => {
                                        this.getList({});
                                        this.dialog_edit = false;
                                        this.dialog = false;
                                        alert("저장했습니다.");

                                    }, 300)
                                })
                                .catch((err) => {
                                });
                        }
                        break;
                    }

                }

            },
            inputNumberFormat(obj) {

                if (this.campItem_edit.campPrice != undefined) {
                    this.campItem_edit.campPrice = this.campItem_edit.campPrice.replace(/[^\d]+/g, '').replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')
                }
            },
            comma(str) {
                if (str != undefined) {
                    str = String(str);
                    return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
                }

            },
            uncomma(str) {
                str = String(str);
                return str.replace(/[^\d]+/g, '');
            },

        },
        mounted() {
            this.$nextTick(() => {

            })
        },

    }
</script>

<style>

</style>

